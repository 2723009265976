import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
// import { TextPlugin } from "gsap/TextPlugin.js";
// import { SlowMo } from "gsap/EasePack.js";


gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false
})

export const initGsap = (pageName) => {


  const rsContainerSections = gsap.utils.toArray('header, section, footer');
  const rsAnimDelay = 15;

  rsContainerSections.forEach((section) => {
    const rsRevealAnimations = gsap.utils.toArray('.RS-animation', section);
    
    rsRevealAnimations.forEach((item, index) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top bottom',
          end: 'bottom top',
          trigger: item,
          toggleClass: 'RS-animation--enter',
          once: true,
          scrub: 1,
          stagger: 5,
        }
      });
  
      item.style.transitionDelay = `${index * rsAnimDelay}ms`;
    });
  });



  const rsRevealAnimations = gsap.utils.toArray('.RS-animation');
  rsRevealAnimations.forEach((item) => {
    gsap.from(item, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: item,
        toggleClass: 'RS-animation--enter',
        once: true,
        scrub: 1,
        stagger: 5,
      }
    });
  });

  const rsLoopedAnimations = gsap.utils.toArray('.RS-looped-animation');

  rsLoopedAnimations.forEach(item => {
    gsap.from(item, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: item,
        toggleClass: 'RS-looped-animation--running',
        scrub: 1
      }
    });
  });



  const topNavBar = document.querySelector('.RS-top-bar');
  const topNavBarMobileIcon = document.querySelector('.RS-top-bar__mobile-nav-icon');
  const floatingPillInner = document.querySelector('.RS-top-bar__floating-pill-inner');

  ScrollTrigger.create({
    start: 0,
    end: 99999,
    onUpdate: ({direction}) => {
      if (direction == -1) {
        topNavBar.classList.remove('RS-top-bar--hidden');
      } else {
        if(!topNavBarMobileIcon.classList.contains('RS-top-bar__mobile-nav-icon--active')){
          topNavBar.classList.add('RS-top-bar--hidden');
        }
      }
    }
  });

  // Add ScrollTrigger for floating pill
  ScrollTrigger.create({
    start: 'top top',
    end: 99999,
    onUpdate: ({progress}) => {
      if (progress > 0) {
        floatingPillInner?.classList.add('RS-top-bar__floating-pill-inner--hidden');
      } else {
        floatingPillInner?.classList.remove('RS-top-bar__floating-pill-inner--hidden');
      }
    }
  });

  

  if(pageName === 'Home'){
    
    /* HERO SECTION */


    // const marquee = document.getElementById('RS-marquee__inner');
    // const items = marquee.querySelectorAll('.RS-hero-marquee__item');
    // const reaction = document.getElementById('RS-hero-marquee__reaction');
    
    // gsap.set(items, { opacity: 0, y: 100 });
  
    // const tl = gsap.timeline({ repeat: -1, defaults: { ease: "power1.out" } });
    // const durationValue = 0.6;
    // const delayValue = 3;
    // const overlapModifier = 0.15;
    // const overlapOffset = (durationValue * 4 + delayValue * 2) / 2 - overlapModifier;
  
  
    // let keyframes = (nextItemHeight) => [
    //   { opacity: 0, y: nextItemHeight, scale: 0.96, duration: durationValue, ease: "sine.out" },
    //   { opacity: 1, y: 0, scale: 1, duration: durationValue, ease: "sine.out" },
    //   { opacity: 1, y: -nextItemHeight, scale: 1, duration: durationValue, delay: delayValue, ease: "sine.out" },
    //   { opacity: 0, y: -2 * nextItemHeight, scale: 0.96, duration: durationValue, delay: delayValue, ease: "sine.out" }
    // ];
    
    // const updateKeyframes = () => {
    //   gsap.set(items, { opacity: 0, y: 100 });
    

    //   tl.clear();
    
    //   items.forEach((item, index) => {
    //     const nextItem = items[index + 1] || items[0];
    //     const nextItemHeight = nextItem.offsetHeight + 16;
    
    //     if (index != 6) {
    //       tl.to(item, { keyframes: keyframes(nextItemHeight) }, index * overlapOffset);
    //     }
    
    //     if (index === 6) {
    //       const specialKeyframes = [
    //         { opacity: 0, y: nextItemHeight, scale: 0.96, duration: durationValue, ease: "sine.out" },
    //         { opacity: 1, y: 0, scale: 1, duration: durationValue, ease: "sine.out" },
    //         { opacity: 0, y: -nextItemHeight, scale: 0.96, duration: durationValue, delay: delayValue, ease: "sine.out" }
    //       ];
    //       tl.to(item, { keyframes: specialKeyframes }, index * overlapOffset);
    //     }
    //   });
    

    //   tl.call(() => {
    //     reaction.classList.add('RS-hero-marquee__reaction--visible');
    //     setTimeout(() => {
    //       reaction.classList.remove('RS-hero-marquee__reaction--visible');
    //     }, 3000 * 2);
    //   }, null, "-=5.5");
    // };
    

    // updateKeyframes();
    
   
    // window.addEventListener('resize', () => {
    //   updateKeyframes();
    // });
    
  
  
    // ScrollTrigger.create({
    //   trigger: marquee,
    //   start: "top bottom",
    //   end: "bottom top",
    //   onEnter: () => tl.play(),
    //   onLeave: () => tl.pause(),
    //   onEnterBack: () => tl.play(),
    //   onLeaveBack: () => tl.pause()
    // });
  


    /* TILES ANIMATIONS */

    ScrollTrigger.create({
      trigger: '#RS-section-about-benefits',
      start: 'top center',
      end: 'bottom bottom',
      animation: gsap.fromTo('.RS-list__tile--about-benefits-first', {yPercent: -5, xPercent: 0, scale:1, opacity: 1 }, {yPercent: 0, xPercent: 0, scale: 1, opacity: 1}),
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-about-benefits',
      start: 'top center',
      end: 'bottom bottom',
      animation: gsap.fromTo('.RS-list__tile--about-benefits-second', {yPercent: 5, xPercent: 0, scale:1, opacity: 1 }, {yPercent: 0, xPercent: 0, scale: 1, opacity: 1}),
      scrub: 1
    })


    ScrollTrigger.create({
      trigger: '#RS-section-about-benefits',
      start: 'top center',
      end: 'bottom bottom',
      animation: gsap.fromTo('.RS-list__tile--about-benefits-third', {yPercent: -5, xPercent: 0, scale:1, opacity: 1 }, {yPercent: 0, xPercent: 0, scale: 1, opacity: 1}),
      scrub: 1
    })


    ScrollTrigger.create({
      trigger: '#RS-section-about-benefits',
      start: 'top center',
      end: 'bottom bottom',
      animation: gsap.fromTo('.RS-list__tile--about-benefits-fourth', {yPercent: -5, xPercent: 0, scale:1, opacity: 1 }, {yPercent: 0, xPercent: 0, scale: 1, opacity: 1}),
      scrub: 1
    })


    

    /* TILE ASSETS ANIMATIONS */

    ScrollTrigger.create({
      trigger: '#RS-section-about-process',
      start: 'top bottom',
      end: 'bottom bottom',
      animation: gsap.fromTo('.RS-list__tile--about-process > div:first-of-type p', {scale: .7, opacity: 1}, {scale: 1, opacity: 1}),
      scrub: 1
    })


    ScrollTrigger.create({
      trigger: '#RS-section-about-product',
      start: 'top bottom',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-tile-product-bg--first', {yPercent: 25, xPercent: -10, scale:.94, opacity: 1, rotationZ: 0, skewX: 0}, {yPercent: 0, xPercent: 0, scale: 1, opacity: 1, rotationZ: 15, skewX: 8}),
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-about-product',
      start: 'top bottom',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-tile-product-bg--second', {yPercent: -25, xPercent: 10, scale:.94, opacity: 1, rotationZ: 0, skewX: 0}, {yPercent: 0, xPercent: 0, scale: 1, opacity: 1, rotationZ: -15, skewX: -8}),
      scrub: 1
    })

    ScrollTrigger.create({
      trigger: '#RS-section-hero',
      start: 'top bottom',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-tile-product-image--hero-desktop', {yPercent: -25, xPercent: 0, scale: 1, opacity: 1 }, {yPercent: 0, xPercent: 0, scale: 1, opacity: 1}),
      scrub: 1
    })


    ScrollTrigger.create({
      trigger: '#RS-section-hero',
      start: 'top bottom',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-tile-product-image--hero-mobile', {yPercent: 25, xPercent: 0, scale: 1, opacity: 1 }, {yPercent: 0, xPercent: 0, scale: 1, opacity: 1}),
      scrub: 1
    })



    /* ABOUT USER */

    ScrollTrigger.create({
      trigger: '#RS-section-about-user',
      start: 'top bottom',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-header--about-user__decor',
        {scaleX: 1, scaleY: 1, rotateZ: 15, opacity: 1, yPercent: 0, xPercent: 0 }, 
        // {scaleX: 1, scaleY: 1, rotateZ: 30, opacity: 1, yPercent: -10, xPercent: 10}
        {scaleX: 1, scaleY: 1, rotateZ: 30, opacity: 1, yPercent: -10, xPercent: 10, ease: "back.in(0.7)"}
      ),
      scrub: 1
    })

    
    const aboutUserCards = gsap.utils.toArray(".RS-list__tile--about-user");
    const spacer = 32;
    let spacerMultiplier;

    function specifySpacerMultiplier() {
      if (window.innerWidth < 860) {
        spacerMultiplier = 4;
      } else {
        spacerMultiplier = 3; 
      }
    }
    specifySpacerMultiplier();

    aboutUserCards.forEach((card, index) => {
      ScrollTrigger.create({
        trigger: card,
        start: `top-=${index * spacer  + spacer*spacerMultiplier} top`,
        endTrigger: '#RS-list--tiles-about-user',
        end: `bottom top+=${400 + (-1 * spacer)}`,
        pin: true,
        pinSpacing: false,
        markers: false,
        id: 'pin',
        invalidateOnRefresh: true,
      });
    });

    const aboutUserHeader = gsap.utils.toArray("#RS-header--about-user");
    let spacer2;

    function specifySpacer2() {
      if (window.innerWidth < 860) {
        spacer2 = 12;
      } else {
        spacer2 = 32; 
      }
    }
    specifySpacer2();

  

    aboutUserHeader.forEach((card, index) => {
      ScrollTrigger.create({
        trigger: card,
        start: `top-=${index * spacer2  + spacer2*3} top`,
        endTrigger: '#RS-list--tiles-about-user',
        end: `bottom top+=${400 + (-1 * spacer)}`,
        pin: true,
        pinSpacing: false,
        markers: false,
        id: 'pin',
        invalidateOnRefresh: true,
      });
    });



    // const aboutReportContent = gsap.utils.toArray(".RS-list__tile--about-report-content");
    // const spacerReportContent = 0;
    // let spacerReportContentMultiplier;

    // function specifyReportContentSpacerMultiplier() {
    //   if (window.innerWidth < 860) {
    //     spacerReportContentMultiplier = 4;
    //   } else {
    //     spacerReportContentMultiplier = 3; 
    //   }
    // }
    // specifyReportContentSpacerMultiplier();

    // aboutReportContent.forEach((card, index) => {
    //   ScrollTrigger.create({
    //     trigger: card,
    //     start: `top-=${index * spacerReportContent  + spacerReportContent*spacerReportContentMultiplier} top`,
    //     endTrigger: '#RS-list--tiles-about-report-content',
    //     end: `bottom top+=${400 + (-1 * spacerReportContent)}`,
    //     pin: true,
    //     pinSpacing: false,
    //     markers: false,
    //     id: 'pin',
    //     invalidateOnRefresh: true,
    //   });
    // });


  }

  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      ScrollTrigger.refresh();
      // setTimeout(() => {
      //   specifySpacerMultiplier();
      //   specifySpacer2();
      // }, 100);
    }
  });

  resizeObserver.observe(document.body);


  

};